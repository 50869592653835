@import "src/scss/variables";

.buttonStyle{
  background: #fff;
  color: $medBlueGray ;
  text-decoration: none;
  border: 1px solid rgba($medBlueGray, 0.25);
  border-radius: 30px;
  padding: 10px 15px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgba(#333, 0.15);

  &:hover{
    background: $medBlueGray;
    color: #fff ;
    box-shadow: none;
  }
}