.image{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
      width: 100%;
      max-width: 600px;
      border: 1px solid rgba(0,0,0,0.1);
      margin: 0px auto 20px;
    }
  }