@import "src/scss/variables";

.video{
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 40px;

  video{
    width: 300px;
  }
}

.twoCol{
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $desktop){
    flex-direction: row;
  }
}