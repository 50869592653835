@import "src/scss/variables";

.footer{

  display: flex;
  justify-content: right;
  border-top: 1px solid #eee;
  background: #fcfcfc;
  padding: calc(#{$gutter} / 2);
  @media only screen and (min-width: $tablet){
    padding: calc(#{$gutter});
  }
  justify-content: space-between;
}