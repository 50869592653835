@import "src/scss/variables";

.portfolioWrapper{
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: $tablet){
    flex-direction: row;
  }

  section{
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    @media only screen and (min-width: $tablet){
      margin: 0 $gutter 0 0;
    }
  }

  h3{
    color: $medBlueGray;
    border-bottom: 2px solid rgba($blueGray, 0.25);
    padding-bottom: 3px;
  }

}