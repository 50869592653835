@import "src/scss/globals";
@import "src/scss/variables";

.hero{
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: $gutter auto calc(#{$gutter} * 2);

  @media only screen and (min-width: $desktop){
    flex-direction: row;
    margin: calc(#{$gutter} * 2) auto calc(#{$gutter} * 4);
  }

  .frame{
    border: 10px solid #fff;
    box-shadow: 0 0 25px rgba(#333, 0.15);
    border-radius: 100%;
    width: 80%;
    max-width: 400px;
    aspect-ratio: 1 / 1;
    background-size: 105%;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: $gutter;

    @media only screen and (min-width: $desktop){
      width: 80%;
      margin-right: 50px;
    }

  }

  .details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 $gutter;

    h1{
      font-size: 14px;
      color: $medBlueGray;
      margin-bottom: 10px;
    }

    h2{
      font-size: 36px;
      -webkit-font-smoothing: antialiased;
    }
  }

  .home{
    h2{
      font-size: 40px;
      text-transform: uppercase;
      -webkit-font-smoothing: antialiased;
    }
  }

}