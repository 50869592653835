.imageRow{
  display: flex;
  flex-display: row;
  margin-bottom: 20px;
}

.image_1{
  max-width: 462px;
  margin-right: 10px;
}

.image_2{
  max-width: 150px;
}

.image_1, .image_2{
  img{width: 100%; border: 1px solid rgba(0,0,0,0.1)}
}

.image_3,.image_4{
  img{width: 100%; border: 1px solid rgba(0,0,0,0.1)}
}

.image_3{
  margin-right: 10px;
}