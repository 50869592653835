@import "src/scss/variables";

.aside{
  border: 1px solid rgba($lightBlueGray, 0.25);
  border-radius: 15px;
  min-width: 250px;
  padding: 15px;

  h4{
    margin-bottom: 5px;
    color: $medBlueGray;
  }

  ul{
    margin-top: 20px;
    padding-left: 25px;

    li{
      list-style: none;
      position: relative;
      margin-bottom: 10px;
      &:before{
        left: -20px;
        top: -2px;
        position: absolute;
        content: '\02609';//''\0262F';
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
}