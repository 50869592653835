.timeline{
  max-width: 600px;
  width: 95%;
  padding: 0 20px;
  border-left: 1px solid #5290B1;
  margin: 0px auto;
}

.time{
  color: #757575;
  font-size: 18px;
  margin-bottom: 5px;
  position: relative;
  .circle{
    height: 10px;
    width: 10px;
    display: block;
    position: absolute;
    left: -25px;
    top: 5px;
    background: #fff;
    border: 1px solid #5290B1;
    border-radius: 10px;
  }
}
.title{
  color: #333333;
  font-size: 18px;
  margin-bottom: 5px;
}
.location{
  color: #757575;
  font-size: 18px;
  margin-bottom: 15px;
}
.description{
  color: #333333;
  margin-bottom: 45px;
}