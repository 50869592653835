.tagline{
  text-align: center;
  padding-top: 20px;
}

.video, .videoMobile{
  width: 100%;
  display: flex;
  justify-content: center;
  video{
    max-width: 600px;
    width: 100%;
    margin: 0px auto 40px;
  }
}

.videoMobile video{
  max-width: 300px;
}

.award{
  img{max-width: 100%;}
}

