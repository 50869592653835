@import 'src/scss/variables.scss';

.header{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: calc(#{$gutter} / 2);
  box-shadow: 0 0 20px rgba(#333, 0.25);
  @media only screen and (min-width: $tablet){
    box-shadow: none;
    padding: calc(#{$gutter} * 2);
    align-items: unset;
  }

  a, a:hover, a:visited{
    text-decoration: none;
    color: inherit;
    padding-left: 0;
    @media only screen and (min-width: $tablet){
      padding-left: $gutter;
    }

    &:hover{
      color: $lightBlueGray
    }
  }

  .home{
    display: flex;
    flex-direction: row;
    padding-left: 0 !important;

    .logo{
      width: 40px;
      height: 40px;
      @media only screen and (min-width: $tablet){
        width: 65px;
        height: 65px;
      }
      @media only screen and (min-width: $desktop){
        width: 85px;
        height: 85px;
      }
    }

    .title{
      font-size: 25px;
      line-height: 25px;

      @media only screen and (min-width: $tablet){
        font-size: 45px;
        line-height: 45px;
      }
      @media only screen and (min-width: $desktop){
        font-size: 55px;
        line-height: 55px;
      }

      span{
        color: $darkBlueGray
      }
    }

    .subTitle{
      font-size: 12px;
      margin: -3px 0 0 10px;

      @media only screen and (min-width: $tablet){
        font-size: 16px;
        margin: -3px 0 0 20px;
      }
      @media only screen and (min-width: $desktop){
        font-size: 18px;
        margin: -3px 0 0 23px;
      }
    }
  }

  .spacer{
    flex-grow: 1;
  }

  .icon{
    display:block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    @media only screen and (min-width: $tablet){
      display:none;
    }

    rect{
      fill: $medBlueGray
    }

    &.hover, &.active{
      rect{
        fill: $lightBlueGray
      }
    }

  }

  nav{
    position: absolute;
    right: 0;

    @media only screen and (max-width: $tablet - 1px){
      //
      //transform: translateY(100%);
      top: 100%;
      padding: $gutter;
      background: #fff;
      flex-direction: column;
      //height: 100%;
      margin-top: 0;
      display: flex;
      transition: transform 0.5s;
      box-shadow: 0 0 20px rgba(#333, 0.25);
      width: 300px;

      &.open{
        transform: translateX(0);
      }

      &.closed{
        transform: translateX(100%);
      }

      a{
        margin-bottom: 15px;
        &:last-of-type{
          margin-bottom: 0px;
        }
      }

    }

    @media only screen and (min-width: $tablet){
      position: relative;
    }
  }

}