@import "src/scss/globals";
@import "src/scss/variables";


.homeButtons{
  display: flex;
  flex-direction: row;

  a{
    @extend .buttonStyle;
    margin-right: 15px;

    &:first-of-type{
      border: 1px solid rgba($medBlueGray, 0.75);
    }
  }
}


.offeringWrapper{

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gutter;
    @media only screen and (min-width: $tablet){
      gap: $gutter * 2;
    }

    > * {

      width: calc(100% - #{$gutter});
      @media only screen and (min-width: $tablet){
        width: calc(50% - (#{$gutter}));
      }

      p{
        margin-bottom: 0;
      }
    }

  }

}
