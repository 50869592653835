@import "src/scss/globals";
@import "src/scss/variables";

.contactPage{
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactWrapper{
  max-width: 600px;
  width: 100%;
  padding: 40px;
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: column;
  margin-bottom: $gutter;
  
  @media only screen and (min-width: $desktop) {
    width: 80%;
    padding: 40px;
  }

  .inputWrapper{
    display: flex;
    flex-direction: column;
  }

  .error{
    color: #cc0000;
    input,textarea{
      border: 1px solid #cc0000
    }
  }

  input,textarea{
    margin-bottom: 20px;
    min-height: 40px;
    padding: 5px;
  }

  button{
    @extend .buttonStyle;
    width: 200px;
    margin-left: auto;
    margin-top: 10px;
  }

  .buttonAnimating{
    background: $medBlueGray !important;
    color: #fff !important;
  }

  .buttonRow{
    display: flex;
    justify-content: space-between;

    span{
      margin-left: -100px;
    }
  }

  .contactTrap{
    height: 0;
    min-height: 0;
    opacity: 0;
    margin: 0;
  }
}