@import "src/scss/variables";

.contentWrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;

  section{
    margin: $gutter;
  }
}