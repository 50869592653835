.video, .videoMobile{
  width: 100%;
  display: flex;
  justify-content: center;
  video{
    max-width: 600px;
    width: 100%;
    margin: 0px auto 40px;
  }
}

.imageRow{
  display: flex;
  flex-display: row;
  margin-bottom: 20px;

  img{
    width: calc(25% - 25px);
    border: 1px solid rgba(0,0,0,0.1);
    margin: 10px;
  }
}