@import "src/scss/variables";

.bulletWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap:  $gutter;

  p{
    width: calc(100% - #{$gutter});
    @media only screen and (min-width: $tablet){
      width: calc(50% - (#{$gutter}));
    }
  }
}