@import "src/scss/variables";

$gutter1: calc(#{$gutter} / 2);
$gutter2: calc(#{$gutter} / 1.8);

.portfolioWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $gutter;
  margin-bottom: 50px;

  .link{
    height: 0;
    padding-top: 56.25%;
    position: relative;
    overflow: hidden;
    width: calc(100%);

    @media only screen and (min-width: $tablet){
      width: calc(50% - (#{$gutter1}));
      padding-top: calc(56.25% / 2);
    }
    @media only screen and (min-width: $desktop){
      width: calc(33% - (#{$gutter2}));
      padding-top: calc(56.25% / 3);
    }

    &:hover,&:focus{
      .banner{
        opacity: 1;
      }

      .background{
        transform: scale(1.2);
      }
    }

  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transform: scale(1);
    transition: transform .5s;
    top: 0;
  }

  .video{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
  }


  .banner{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(255,255,255,0.8);
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    opacity: 1;

    @media only screen and (min-width: $tablet){
      opacity: 0;
    }

    transition: opacity 0.5s ease-out;
    color: #333333;
    text-decoration: none;
  }

}
