@import "src/scss/variables";

.portfolioNav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a{
    text-decoration: none;
    color: $darkBlueGray;
  }

  h1{
    padding: 0 20px;
    text-align: center;
  }
}