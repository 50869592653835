$darkBlueGray: #043d5c;
$medBlueGray: #567899;
$blueGray: #32779C;
$lightBlueGray: #5290B1;

$gutter: 25px;

$mobile: 500px;
$tablet: 800px;
$desktop: 1024px;
$lgDesktop: 1400px;